<template>
  <div class="main">
    <div class="response-container success" v-if="responseType==='success'">
      <div class="header">
        <span class="icon-wrapper success">
          <b-icon icon="check-lg"></b-icon>
        </span>
      </div>
      <div class="body">
        <h3>Congratulations!</h3>
        <p>The payment was made successfully</p>
        <!-- <p>The <strong>login</strong> and <strong>password</strong> for accessing the personal cabinet have been sent to
          your email</p> -->
      </div>
      <div class="footer">
        <b-button variant="success" @click="onClickButton">
          OK
        </b-button>
      </div>
    </div>
    <div class="response-container error" v-else>
      <div class="header">
        <span class="icon-wrapper error">
          <b-icon icon="x-lg"></b-icon>
        </span>
      </div>
      <div class="body">
        <h3>Opps! Something went wrong</h3>
        <p>{{ reasonDetail }}</p>
      </div>
      <div class="footer">
        <b-button variant="danger" @click="onClickButton">
          OK
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentResponsePage",
  data() {
    return {
      responseType: null,
      token: null,
      payment_transaction_id: null,
      reasonDetail: null,
      apiUrl: process.env.VUE_APP_API_URL
    }
  },
  methods: {
    onClickButton() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('event');
      window.location.href = 'https://hoteliers.uz/'
    },
    getPaymentDetail() {
      let url = `${this.apiUrl}/payment/v2/payment/detail/?token=${this.token}`;
      if (this.payment_transaction_id) {
        url = `${this.apiUrl}/payment/v2/payment/detail/?transaction_id=${this.payment_transaction_id}`;
      }
      axios.get(url).then(({data}) => {
        if (data.message) {
          this.$toast.warning(data.message);
        } else {
          if (data.reasonDetail) {
            this.reasonDetail = data.reasonDetail
          }
        }
      }).catch(({response}) => {
        this.$toast.error(response.data.message)
      })
    }
  },
  mounted() {
    this.responseType = this.$route.name;
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
    if (this.$route.query.payment_transaction_id) {
      this.payment_transaction_id = this.$route.query.payment_transaction_id;
    }
    if (this.payment_transaction_id || this.token) {
      this.getPaymentDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFF;
}

.response-container {
  max-width: 400px;
  width: 100%;
  height: 350px;
  box-shadow: 0 16px 24px rgba(24, 25, 26, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  .header {
    position: relative;

    .icon-wrapper {
      position: absolute;
      top: -150px;
      transform: translateX(-50%);
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #ffffff;
      font-size: 30px;

      &.success {
        background-color: #00c851;
      }

      &.error {
        background-color: #ff3547;
      }
    }
  }

  .body {
    text-align: center;
  }

  .footer {
    width: 100%;
    position: relative;

    button {
      position: absolute;
      border: none;
      height: 45px;
      width: 100%;
      border-radius: 15px;
    }
  }
}
</style>